import { mixins, theme } from '../../../../styles'

export interface ICarouselHighlightsBorder {
  borderColor: string
  position: string
}

export function CarouselHighlightsBorder({
  borderColor,
  position,
}: ICarouselHighlightsBorder): JSX.Element {
  return (
    <>
      <hr className={`CarouselHighlightsBorder--${position}`} />

      <style jsx>{`
        hr {
          position: static;
          width: 100%;
          border-top: 1px solid ${borderColor || theme.cssVars.focusBlue};
          z-index: 3;
        }

        @media ${mixins.mediaQuery.tablet} {
          hr {
            width: calc(100% - 24px);
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          hr {
            width: calc(100% - 122px);
          }
        }

        hr::after {
          position: absolute;
          margin: 0 auto;
          left: 0;
          right: 0;
          content: '';
          width: 60%;
          height: 6px;
          background-color: ${borderColor || theme.cssVars.focusBlue};
        }

        hr.CarouselHighlightsBorder--bottom::after {
          bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        hr {
          align-self: start;
          border-bottom: none;
          border-left: none;
          border-right: none;
          margin: 0;
        }
      `}</style>
    </>
  )
}
